var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-card-title',{staticClass:"font-large-1"},[_c('b-row',[_c('b-col',{attrs:{"lg":"12","md":"12"}},[_c('div',{staticClass:"brand-img d-flex justify-content-center"},[_c('b-img',{staticClass:"w-25",attrs:{"fluid":"","src":_vm.imgUrl,"alt":""}})],1)])],1)],1),_c('validation-observer',{ref:"verifyMetadataForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return passes(_vm.submitMetadata)}}},[_c('b-row',[(_vm.member_type !== 2 && _vm.member_type !== 3)?_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('h4',[_vm._v("Next of Kin Details")]),_c('b-form-group',{attrs:{"label-for":"first_name","label":_vm.labels.next_of_kin_first_name}},[_c('validation-provider',{attrs:{"name":"Next of Kin's First Name","vid":"first_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"next-of-kin-first-name","name":"next-of-kin-first-name","state":errors.length > 0 ? false:null,"placeholder":"Jane","autofocus":"","type":"text","onkeydown":"return /[a-z]/i.test(event.key)"},model:{value:(_vm.kin_information.first_name),callback:function ($$v) {_vm.$set(_vm.kin_information, "first_name", $$v)},expression:"kin_information.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"last_name","label":_vm.labels.next_of_kin_last_name}},[_c('validation-provider',{attrs:{"name":"Next of Kin's Last Name","vid":"last_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"next-of-kin-last-name","name":"next-of-kin-last-name","state":errors.length > 0 ? false:null,"placeholder":"Doe","type":"text","onkeydown":"return /[a-z]/i.test(event.key)"},model:{value:(_vm.kin_information.last_name),callback:function ($$v) {_vm.$set(_vm.kin_information, "last_name", $$v)},expression:"kin_information.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"phone_number","label":_vm.labels.next_of_kin_phone_number}},[_c('validation-provider',{attrs:{"name":"Next of Kin's Phone Number","vid":"phone_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"next-of-kin-phone-number","name":"next-of-kin-phone-number","state":errors.length > 0 ? false:null,"placeholder":"07********","type":"number"},model:{value:(_vm.kin_information.phone_number),callback:function ($$v) {_vm.$set(_vm.kin_information, "phone_number", $$v)},expression:"kin_information.phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"relationship","label":_vm.labels.next_of_kin_relationship}},[_c('validation-provider',{attrs:{"name":"Next of Kin Relationship","vid":"relationship"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"next-of-kin-relationship","name":"next-of-kin-relationship","placeholder":"Enter your relationship","type":"text","onkeydown":"return /[a-z]/i.test(event.key)"},model:{value:(_vm.kin_information.relationship),callback:function ($$v) {_vm.$set(_vm.kin_information, "relationship", $$v)},expression:"kin_information.relationship"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"identification document","label":_vm.labels.next_of_kin_id_document}},[_c('validation-provider',{attrs:{"name":"Next of Kin ID Document","vid":"id_document"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"next-of-kin-identification-document","name":"next-of-kin-identification-document","accept":".pdf"},model:{value:(_vm.kin_information.identification_document),callback:function ($$v) {_vm.$set(_vm.kin_information, "identification_document", $$v)},expression:"kin_information.identification_document"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_kin_id_document_size_error),expression:"show_kin_id_document_size_error"}],staticClass:"text-danger"},[_vm._v("File Too Large(Max 5MB)")])]}}],null,true)})],1)],1):_vm._e(),_c('b-col',{attrs:{"lg":_vm.member_type != 2 ? 6 : 12,"sm":"12"}},[_c('h4',[_vm._v("Payment Information")]),_c('h5',[_vm._v("Bank Details")]),_c('b-form-group',{attrs:{"label-for":"bank_name","label":"Select Bank Name"}},[_c('validation-provider',{attrs:{"name":"Bank Name","vid":"bank_name","rules":_vm.require_bank_details ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"bank-name","options":_vm.banks_options,"name":"bank-name","state":errors.length > 0 ? false:null},model:{value:(_vm.payment_information.bank_name),callback:function ($$v) {_vm.$set(_vm.payment_information, "bank_name", $$v)},expression:"payment_information.bank_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"bank_account_name","label":"Enter Bank Account Name"}},[_c('validation-provider',{attrs:{"name":"Bank Account Name","vid":"bank_account_name","rules":_vm.require_bank_details ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"bank-account-name","name":"bank-account-name","state":errors.length > 0 ? false:null,"type":"text"},model:{value:(_vm.payment_information.bank_account_name),callback:function ($$v) {_vm.$set(_vm.payment_information, "bank_account_name", $$v)},expression:"payment_information.bank_account_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"bank_account_number","label":"Enter Bank Account Number"}},[_c('validation-provider',{attrs:{"name":"Bank Account number","vid":"bank_account_number","rules":_vm.require_bank_details ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"bank-account-number","name":"bank-account-number","state":errors.length > 0 ? false:null,"type":"number"},model:{value:(_vm.payment_information.bank_account_number),callback:function ($$v) {_vm.$set(_vm.payment_information, "bank_account_number", $$v)},expression:"payment_information.bank_account_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"bank_branch","label":"Enter Bank Branch"}},[_c('validation-provider',{attrs:{"name":"Bank Branch","vid":"bank_branch","rules":_vm.require_bank_details ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"bank-branch","name":"bank-branch","state":errors.length > 0 ? false:null,"type":"text","onkeydown":"return /[a-z]/i.test(event.key)"},model:{value:(_vm.payment_information.bank_branch),callback:function ($$v) {_vm.$set(_vm.payment_information, "bank_branch", $$v)},expression:"payment_information.bank_branch"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('h5',[_vm._v("Mobile Money")]),_c('b-form-group',{attrs:{"label-for":"mobile_money_phone_number","label":"Enter Mobile Money Registered Phone Number"}},[_c('validation-provider',{attrs:{"name":"Mobile Money Phone Number","vid":"mobile_money_phone_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mobile-money-phone-number","name":"mobile-money-phone-number","type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.payment_information.mobile_money_phone_number),callback:function ($$v) {_vm.$set(_vm.payment_information, "mobile_money_phone_number", $$v)},expression:"payment_information.mobile_money_phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isValidPhoneNumber),expression:"!isValidPhoneNumber"}],staticClass:"text-danger"},[_vm._v("Enter a valid phone number")])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"mobile_money_provider","label":"Select Mobile Money Service Provider"}},[_c('validation-provider',{attrs:{"name":"Mobile Money Provider","vid":"mobile_money_provider"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"mobile-money-provider","name":"mobile-money-provider","options":_vm.mobile_service_providers,"state":errors.length > 0 ? false:null},model:{value:(_vm.payment_information.mobile_money_provider),callback:function ($$v) {_vm.$set(_vm.payment_information, "mobile_money_provider", $$v)},expression:"payment_information.mobile_money_provider"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"mobile_money_registered_name","label":"Enter Mobile Money Registered Name"}},[_c('validation-provider',{attrs:{"name":"Mobile Money Registered Name","vid":"mobile_money_registered_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mobile-money-registered-name","name":"mobile-money-registered-name","type":"text","state":errors.length > 0 ? false:null},model:{value:(_vm.payment_information.mobile_money_registered_name),callback:function ($$v) {_vm.$set(_vm.payment_information, "mobile_money_registered_name", $$v)},expression:"payment_information.mobile_money_registered_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_enter_valid_mobile_money_name),expression:"show_enter_valid_mobile_money_name"}],staticClass:"text-danger"},[_vm._v("Enter a valid name")])]}}],null,true)})],1)],1)],1),_c('b-button',{staticClass:"w-25 float-right",attrs:{"variant":"primary","type":"submit","block":"","disabled":_vm.isLoading}},[_vm._v(" Submit "),(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1)]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }